import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import axios from 'axios';
import { fileToBase64 } from '@/src/helpers/image';
import { RootState } from '../store';
import storage from 'redux-persist/lib/storage';


export const createGoogleAccount = createAsyncThunk(
  'user/createGoogleAccount',
  async( formData:{}, {}) => {
    //axios call to create user
    const config = { headers: {
      'Content-Type': 'Application/json',
    }}  

    //get env var
    const res = await fetch('/api/env/backend-url')
    const {backendUrl} = await res.json();
    if (!backendUrl) {
      console.error('Backend URL env is missing');
      return 
    }

    const response = await axios.post(
      `${backendUrl}/api/user/google-login-signup/`,
      formData,
      config
    )
    return response.data
  }
)



export const updateNotificationLastChecked = createAsyncThunk(
  'user/updateNotificationLastChecked',
  async (_, {getState}) => {
    //get state to access token and current notif last checked
    const userState:any = getState();
    const {user} = userState.user.data;

    let token;
    if ('token' in user) {  token = user.token } 
    //check for token in current state first
    if (token) {
      const {notification_last_checked} = user
      const currentDate = new Date();
      if (notification_last_checked) {
        const lastNotification = new Date(notification_last_checked)
        // update notification
        if (lastNotification < currentDate) {
          //create config header object
          const config = {
            headers: {
              'Content-Type': 'Application/json',
              Authorization: `Bearer ${token}`
            }
          }

          //get env var
          const res = await fetch('/api/env/backend-url')
          const {backendUrl} = await res.json();
          if (!backendUrl) {
            console.error('Backend URL env is missing');
            return userState.user.data
          }

          //make patch to update read
          const response = await axios.post(
            `${backendUrl}/api/user/update-user/`,
            {notification_last_checked: currentDate},
            config
          )
          return response.data
        }
      }
    }
    return userState.user.data
  }
)



/** SLICE STATE */

interface UserState {
  data: {
    token: string; id: string;
    email: string;
    refresh: string; access: string;
    profilePicture: string; username: string;
    firstName: string; lastName: string;
    email_verified: boolean;
    google_login: boolean;
    is_organizer: boolean;
    passwordSet: boolean;
  };
  status: string;
  error: string | null;
}

const initialState: UserState = {
  data: {
    token: '', id: '',
    email: '',
    refresh: '', access: '',
    profilePicture: '', username: '',
    firstName: '', lastName: '',
    email_verified: false,
    google_login: false,
    is_organizer: false,
    passwordSet: false
  },
  status: 'idle',
  error: null,
}


export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    userLogin: (state, action) => {
      state.data = action.payload
    },
    userLogout: (state) => {
      storage.removeItem('persist:root'); // Clear Redux Persist storage
      state.data = initialState.data
    },
    setUserData: (state, action) => {
      state.data = action.payload
    },

    updateUser: (state, action) => {
      state.data = {...state.data, ...action.payload}
    },
    updateUserProfilePicture: (state, action) => {
      if (state.data) {
        state.data = {...state.data, profilePicture: action.payload}
      }
    }

  },
  extraReducers: (builder) => {
    
    //createGoogleAccount
    builder.addCase(createGoogleAccount.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createGoogleAccount.fulfilled, (state, action) => {
      state.status = 'succeeded'
      state.data = action.payload
    })
    builder.addCase(createGoogleAccount.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

    builder.addCase(updateNotificationLastChecked.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(updateNotificationLastChecked.fulfilled, (state, action) => {
      state.status = 'succeeded'
      // state.data = action.payload
      state.data = action.payload
    })
    builder.addCase(updateNotificationLastChecked.rejected, (state, action)=> {
      state.status = 'failed'
      state.error = action.error.message || 'An error occurred'
    })

  },
});

//reducers
export const {userLogin, userLogout, setUserData, updateUser, 
  // updateUserProfile, 
  updateUserProfilePicture} = userSlice.actions;

//export const selectUser = (state) = state.user
export default userSlice.reducer;


export const selectUser = (state: RootState) => state.user.data || initialState.data;